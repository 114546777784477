.popup {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 99;
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

input[type="date"]:focus {
    border: none;
    outline: none;
}

.popup-content {
    height: fit-content;
    width: 80%;
    overflow: hidden;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.filter-heading {
    font-size: 24px;
    font-weight: bold;
}

.filter-table {
    width: 100%;
}

.filter-table th {
    text-transform: capitalize;
    text-align: left;
}

.filter-table input {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.action-buttons {
    display: flex;
    flex-direction: row;
}

.apply-button,
.cancel-button {
    height: 50px;
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.apply-button {
    background-color: #ffef03;
    border: 1px solid #e3d400;
    border-radius: 12px;
    margin-right: 10px;
}

.cancel-button {
    border: 2px solid #ffef03;
    background-color: white;
    border-radius: 12px;
}

.tablerow {
    cursor: pointer;
    background-color: transparent;
    border-radius: 15px;
    transition: all 0.1s ease-in-out;
    box-shadow: none;
}

.tablerow:hover {
    border-radius: 15px;
    transform: scale(1.02);
    background-color: #eee;
    /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
}

.pageBtns {
    background-color: #ffef03;
    flex: 1;
    border-radius: 15px;
    margin: 20px;
    max-width: 8vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: black;
    /* box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.4); */
    border: none;
}

.pageBtns:active {
    box-shadow: none;
}
