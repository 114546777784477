.detailContainer {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    gap: 20px;
    display: flex;

    div {
        p {
            font-size: 20px;
        }

        button {
            width: 100%;
            height: 40px;
            background-color: white;
            border-radius: 12px;
            border: 1px solid #eee;
        }

        button.red {
            background-color: rgba(255, 142, 142, 0.2);
            border: 1px solid red;
        }

        button.green {
            background-color: rgba(17, 255, 0, 0.2);
            border: 1px solid green;
        }
    }
}

.statsDiv {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    gap: 20px;
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
